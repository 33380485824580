<template>
  <div>
    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <TermPicker
          outlined
          text
          v-model="term"
          :range="[-3, 1]"
        /><v-spacer></v-spacer>
      </v-row>
    </v-container>
    <v-card>
      <v-system-bar>Kurse</v-system-bar>
      <CourseList :items="items" />
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import CourseList from "@/components/CourseList.vue";
import TermPicker from "common/components/TermPicker.vue";

export default defineComponent({
  name: "PersonCourses",
  components: { CourseList, TermPicker },
  props: ["person"],
  data() {
    return {
      items: [],
      term: null,
    };
  },
  watch: {
    person() {
      this.fetchData();
    },
    term() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.term) return;
      this.items = await this.apiList({
        resource: "course/course",
        query: `person=${this.person.id}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });
    },
  },
});
</script>
